export function mouseover() {
  const target = document.querySelectorAll('.js-mouseover');

  for (let i = 0; i < target.length; i++) {
    let tag = target[i];
    tag.addEventListener('mouseover', () => {
      tag.classList.add('is-view');
    }, false);
    tag.addEventListener('mouseleave', () => {
      tag.classList.remove('is-view');
    }, false);
  }
}
