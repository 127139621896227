export function hamburger() {
  const Trigger = document.querySelector('.js-hamburgerTrigger');
  const header = document.querySelector('.l-header');
  const menu = document.querySelector('.l-header_spNavi');
  const close = document.querySelector('.l-header_spNavi--colse');
  
  Trigger.addEventListener('click', () => {
    Trigger.classList.toggle('is-active');
    header.classList.toggle('is-active');
    menu.classList.toggle('is-view');
  });

  close.addEventListener('click', () => {
    Trigger.classList.toggle('is-active');
    header.classList.toggle('is-active');
    menu.classList.toggle('is-view');
  });
}
