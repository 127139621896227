export function dropdown() {
  $(function() {
    $('.js-dropdown').on('click', function(){
      $(this).toggleClass('is-open');
      $(this).next().slideToggle();
    });

    //spのみ
    if (window.matchMedia('(max-width: 767px)').matches) {
      //スマホ処理
      $('.js-dropdownsp').on('click', function(){
        $(this).toggleClass('is-open');
        $(this).next().slideToggle();
      });
    }
  });
}